import { z } from "zod";
import { dateSchema } from "../utilities";
import { practitionerCardSchema } from "./practitioner";
import { sessionSchema, sessionSlotSchema } from "./session";

namespace PaymentStatus {
	export const PENDING = "pending";
	export const SUCCESS = "success";
	export const FAILURE = "failure";
	export const CANCELED = "canceled";
}

export type PaymentStatusOption =
	| typeof PaymentStatus.PENDING
	| typeof PaymentStatus.SUCCESS
	| typeof PaymentStatus.FAILURE
	| typeof PaymentStatus.CANCELED;

export const PaymentStatusEnum = z.enum([
	PaymentStatus.PENDING,
	PaymentStatus.SUCCESS,
	PaymentStatus.FAILURE,
	PaymentStatus.CANCELED,
]);

// same as stripe refund status
namespace PaymentRefundStatus {
	export const PENDING = "pending";
	export const REQUIRES_ACTION = "requires_action";
	export const SUCCEEDED = "succeeded";
	export const FAILED = "failed";
	export const CANCELED = "canceled";
}

export type PaymentRefundStatusOption =
	| typeof PaymentRefundStatus.PENDING
	| typeof PaymentRefundStatus.REQUIRES_ACTION
	| typeof PaymentRefundStatus.SUCCEEDED
	| typeof PaymentRefundStatus.FAILED
	| typeof PaymentRefundStatus.CANCELED;

export const PaymentRefundStatusEnum = z.enum([
	PaymentRefundStatus.PENDING,
	PaymentRefundStatus.REQUIRES_ACTION,
	PaymentRefundStatus.SUCCEEDED,
	PaymentRefundStatus.FAILED,
	PaymentRefundStatus.CANCELED,
]);

namespace PaymentType {
	export const BOOKING_SESSION = "booking_session";
	export const SUBSCRIBE = "subscribe";
}

export type PaymentTypeOption =
	| typeof PaymentType.BOOKING_SESSION
	| typeof PaymentType.SUBSCRIBE;

export const PaymentTypeEnum = z.enum([
	PaymentType.BOOKING_SESSION,
	PaymentType.SUBSCRIBE,
]);

namespace BookingType {
	export const ACTIVE = "active";
	export const ARCHIVE = "archive";
	export const CANCELED = "canceled";
}

export type BookingTypeOption =
	| typeof BookingType.ACTIVE
	| typeof BookingType.ARCHIVE
	| typeof BookingType.CANCELED;

export const BookingTypeEnum = z.enum([
	BookingType.ACTIVE,
	BookingType.ARCHIVE,
	BookingType.CANCELED,
]);

export const contactInfoSchema = z.object({
	firstname: z.string().min(1),
	lastname: z.string().min(1),
	email: z.string().email(),
	phone: z.string(),
	location: z.string().optional(),
	additionalAddress: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
});

export const bookingSessionPaymentSchema = z.object({
	session: sessionSchema,
	slot: sessionSlotSchema,
	contactInfo: contactInfoSchema,
	userId: z.string(),
	priceIndex: z.number(),
	baseUrl: z.string(),
	couponId: z.string().optional(),
});

export const bookingSessionPaymentRefundSchema = z.object({
	id: z.string(),
	status: PaymentRefundStatusEnum,
	// refund reason fill by user
	reason: z.string().optional(),
	// if refund failed, record the reason from stripe
	failureReason: z.string().optional(),
	createdAt: dateSchema,
	updatedAt: dateSchema.optional(),
});

export const bookingSessionPaymentLogSchema = z.object({
	_id: z.string().optional(),
	bookingSessionId: z.string(),
	slotId: z.string(),
	userId: z.string(),
	practitionerId: z.string(),
	receiptUrl: z.string().optional(),
	// stripe ids: checkout session id and payment intent id
	paymentSessionId: z.string().optional(),
	paymentIntentId: z.string().optional(),
	// snapshot for the order start
	fee: z.number(),
	currency: z.string(),
	minCancelAllowTime: z.number().min(0).optional().default(24), // hour

	contactInfo: contactInfoSchema,
	status: PaymentStatusEnum,
	refund: bookingSessionPaymentRefundSchema.optional(),
	// the slot start and end time can be used to calculate when the log is out of date and when user is not able to cancel the order
	slotStartAt: dateSchema,
	slotEndAt: dateSchema,
	createdAt: dateSchema,
	updatedAt: dateSchema.optional(),
	canceledAt: dateSchema.optional(),
	cancelReason: z.string().optional(),
});

export const bookingSessionOrderSchema = z.object({
	_id: z.string(),
	paymentLog: bookingSessionPaymentLogSchema,
	session: sessionSchema,
	slot: sessionSlotSchema,
	practitioner: practitionerCardSchema,
});

export const paymentintentMetadataSchema = z.object({
	orderId: z.string(),
	type: PaymentTypeEnum,
	slotId: z.string().optional(),
	userId: z.string().optional(),
	priceIndex: z.string().optional(),
	bookingSessionId: z.string().optional(),
	couponId: z.string().optional(),
});

export type BookingSessionPayment = z.infer<typeof bookingSessionPaymentSchema>;
export type BookingSessionPaymentRefund = z.infer<
	typeof bookingSessionPaymentRefundSchema
>;
export type BookingSessionPaymentLog = z.infer<
	typeof bookingSessionPaymentLogSchema
>;
export type BookingSessionOrder = z.infer<typeof bookingSessionOrderSchema>;
export type PaymentintentMetadata = z.infer<typeof paymentintentMetadataSchema>;

export const subscriptionPaymentSchema = z.object({
	practitionerId: z.string(),
	subscriptionId: z.string(),
	currency: z.string(),
	baseUrl: z.string(),
});

export const subscriptionRequestFromFrontendSchema = z.object({
	uniqueURL: z.string(),
	subscriptionId: z.string(),
	currency: z.string(),
	baseUrl: z.string(),
});

export const subscriptionPaymentLogSchema = z.object({
	_id: z.string().optional(),
	paymentSessionId: z.string().optional(),
	customerId: z.string().optional(),
	practitionerId: z.string(),
	receiptUrl: z.string().optional(),
	acknowledged: z.boolean().optional(),

	subscriptionId: z.string(),
	// snapshot for the order start
	currency: z.string(),
	status: PaymentStatusEnum,

	createdAt: dateSchema,
	updatedAt: dateSchema.optional(),
	canceledAt: dateSchema.optional(),
});

export type SubscriptionPayment = z.infer<typeof subscriptionPaymentSchema>;
export type SubscriptionRequestFromFrontend = z.infer<
	typeof subscriptionRequestFromFrontendSchema
>;
export type SubscriptionPaymentLog = z.infer<
	typeof subscriptionPaymentLogSchema
>;

export type BookingContactInfo = z.infer<typeof contactInfoSchema>;
