import { z } from "zod";
import { currencyCodeSchema } from "../currencies";
import { latLngSchema } from "../destination";
import { rsvpSchema } from "../rsvp";
import { dateSchema } from "../utilities";
import {
	apiRatingSchema,
	availableApisSchema,
	OpenWeekDaySchema,
	photoSchema,
	recoApiSchema,
	reviewSchema,
	viatorDataSchema,
	videoSchema,
} from "./properties";

// Englobing properties for all scrapers
// May still be refined, we'll see when we'll ticker with the scrapers
export const scraperRecoSchema = z.object({
	address: z.string().optional(),
	apiRecoId: z.string(),

	bookUrl: z.string().optional(),
	bookProvider: availableApisSchema.optional(),

	categories: z.string().array(),
	city: z.string().optional(),
	currency: currencyCodeSchema.optional(),

	description: z.string().optional(),

	email: z.string().optional(),
	eventEnd: z.array(z.string()).or(z.string()).optional(),
	eventGroupDescription: z.string().optional(),
	eventGroupName: z.string().optional(),
	eventOrganizer: z.string().optional(),
	eventRecurrentGroup: z.string().optional(),
	eventStart: z.array(z.string()).or(z.string()).optional(),
	eventTargetGroup: z.string().optional(),
	eventVenueDescription: z.string().optional(),
	eventVenueName: z.string().optional(),

	features: z.string().array().optional(),
	freeEvent: z.boolean().default(false),

	geometry: latLngSchema.optional(),

	hide: z.boolean().default(false),
	hideEventStartTime: z.boolean().default(false),
	hideEventEndTime: z.boolean().default(false),
	hideReason: z.string().optional(),
	hybridEvent: z.boolean().default(false),

	internationalPhoneNumber: z.string().optional(),

	label: z.string().optional(),
	languages: z.string().array().optional(),
	lastUpdated: dateSchema.optional(),
	logos: z.string().array().optional(),
	longTermEvent: z.boolean().default(false),

	menuUrl: z.string().url().optional(),

	name: z.string(),

	onlineEvent: z.boolean().default(false),
	openingHours: OpenWeekDaySchema.array().optional(),

	paymentMethods: z.string().array().optional(),
	photos: photoSchema.array().optional(),
	placeId: z.string().optional(),
	price: z.number().optional(),
	priceRanges: z
		.object({
			name: z.string(),
			maxPrice: z.number(),
			minPrice: z.number(),
			currency: z.string().optional(),
		})
		.array()
		.optional(),
	priceProvider: z.string().optional(),
	propertyId: z.string().optional(),
	publisher: z.string().optional(),
	publisherLogo: z.string().optional(),

	rating: z.number().optional(),
	ratings: apiRatingSchema.array().optional(),
	reviews: reviewSchema.array().optional(),
	reviewsUrl: z.string().url().optional(),
	rsvp: rsvpSchema.optional(),
	rsvpLink: z.string().url().optional(),

	shouldMatch: z.boolean().default(true),
	sourceApi: recoApiSchema,

	url: z.string().optional(),

	viatorData: viatorDataSchema.optional(),
	videos: videoSchema.array().optional(),

	websiteUrl: z.string().url().optional(),
});
export type ScraperReco = z.infer<typeof scraperRecoSchema>;
