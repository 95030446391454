import {
	BooleanWithDescriptionField,
	StringSelectWithDescriptionField,
	StringWithDescriptionField,
} from "@cruncho/components";
import {
	CountryCode,
	PopulatedDestination,
} from "@cruncho/cruncho-shared-types";
import { ccToLocale } from "@cruncho/utils/helpers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik, FormikErrors, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { api } from "services/api";

interface IframeParameters {
	destination: string;
	domain: string;
	language: CountryCode;
	customDomain: string;
	title: string;
	script: string;
	iframeResizing: boolean;
}
interface ObserverProps {
	handleChange: (values: IframeParameters) => void;
}
const Observer = ({ handleChange }: ObserverProps) => {
	const { values }: { values: IframeParameters } = useFormikContext();

	useEffect(() => {
		handleChange(values);
	}, [values]);

	return null;
};
export const IframeGeneratorView = () => {
	// Initial values so that Formik understands the types
	const initialValues: IframeParameters = {
		destination: "",
		domain: "",
		language: "GB",
		customDomain: "",
		title: "",
		script: "sdk",
		iframeResizing: false,
	};

	// Destination options
	const [destinations, setDestinations] =
		useState<Array<PopulatedDestination>>();
	const [destinationOptions, setDestinationOptions] = useState<string[]>([]);
	useEffect(() => {
		api.destination
			.getAllWithVenues()
			.then((data) => {
				data.sort((a, b) => (a._id < b._id ? -1 : 1));
				setDestinations(data);
				setDestinationOptions(data.map((destination) => destination._id));
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	// Domain options
	const [domainOptions, setDomainOptions] = useState<string[]>([]);

	// Language options
	const [languageOptions, setLanguageOptions] = useState<string[]>([]);

	// Script
	const scriptOptions = ["sdk", "sdk-no-scroll", "sdk-without-ga"];

	// Selected destination
	const handleChange = (values: IframeParameters) => {
		if (!destinations) return;

		const filteredDestinations = destinations.filter(
			(destination) => destination._id === values.destination,
		);
		if (!filteredDestinations.length) return;
		const selectedDestination = filteredDestinations[0];

		const domains = selectedDestination.subdomainMatch.split("|");
		domains.unshift(selectedDestination._id);
		setDomainOptions(domains);

		const languages = selectedDestination.features.availableCCs;
		setLanguageOptions(languages);
	};

	// Generated code
	const [code, setCode] = useState("");
	const handleSubmit = (values: IframeParameters) => {
		if (!destinations) return;

		if (!values.domain && !values.customDomain) return;

		const filteredDestinations = destinations.filter(
			(destination) => destination._id === values.destination,
		);
		if (!filteredDestinations) return;
		const selectedDestination = filteredDestinations[0];

		const locale =
			values.language === "GB" ? "" : ccToLocale(values.language as CountryCode);

		const domain =
			values.customDomain.length > 0
				? values.customDomain.startsWith("http")
					? values.customDomain
					: `https://${values.customDomain}`
				: `https://${
						values.domain.slice(-1) === "."
							? values.domain.slice(0, -1)
							: values.domain
					}.cruncho.co/${locale}`;

		const title =
			values.title.length > 0
				? values.title
				: `Cruncho ${
						selectedDestination.name.toUpperCase()[0]
					}${selectedDestination.name.slice(1).toLowerCase()} Guide`;

		const newCode = `<link\n    rel="stylesheet"\n    href="https://s3.cruncho.co/cruncho-scripts/iframe-style.css"\n/>\n<!--  cruncho iframe sdk -->\n<script type="text/javascript">var CRUNCHO_IFRAME_URL = "${domain}";</script>\n<script src="https://s3.cruncho.co/cruncho-scripts/${
			values.script
		}.js"></script>\n\n<!-- allowfullscreen is deprecated but is needed on old firefox browsers -->\n<iframe\n    id="cruncho_iframe"\n    title="${title}"\n    allow="geolocation; fullscreen; clipboard-read; clipboard-write"\n    src="${domain}"\n    allowfullscreen\n></iframe>\n${
			values.iframeResizing
				? '\n<script src="https://cdn.jsdelivr.net/gh/davidjbradshaw/iframe-resizer/js/iframeResizer.min.js"></script>\n\n<script type="text/javascript">\n    function resizeIframe(options) {\n        iFrameResize(options, "#cruncho_iframe");\n    }\n    function handleRouterChange() {\n        var currentUrl = window.location.href;\n        if (currentUrl.includes("/search") || currentUrl.includes("/category")) {\n            var iframeElement = document.getElementById("cruncho_iframe");\n            iframeElement.style.height = "90vh";\n            resizeIframe({ scrolling: false, checkOrigin: false, autoResize: false });\n        } else {\n            resizeIframe({ heightCalculationMethod: "taggedElement", scrolling: false, checkOrigin: false });\n        }\n    }\n    handleRouterChange();\n    window.onpopstate = handleRouterChange;\n</script>'
				: ""
		}`;

		setCode(newCode);
	};

	// Validation
	const validate = (values: IframeParameters) => {
		const errors: FormikErrors<IframeParameters> = {};

		if (
			!destinationOptions.filter(
				(destination) => destination === values.destination,
			).length
		) {
			errors.destination = "Wrong destination";
		}

		if (
			!errors.destination &&
			values.domain !== values.destination &&
			!domainOptions.includes(values.domain) &&
			!values.customDomain
		) {
			errors.domain = "Wrong domain";
		}

		if (!errors.script && !scriptOptions.includes(values.script)) {
			errors.script = "Wrong script";
		}

		return errors;
	};

	return (
		<div className="w-[90%] m-auto">
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={validate}
			>
				{() => (
					<Form>
						<Observer handleChange={handleChange} />
						<Grid container style={{ flex: 1, flexDirection: "column" }} spacing={1}>
							<Grid item>
								<Stack spacing={1}>
									<Typography>Parameters</Typography>
									<StringSelectWithDescriptionField
										name="Destination"
										databaseName="destination"
										description="Select the destination for which to generate the widget code"
										arrayOptions={destinationOptions}
									/>
									<StringSelectWithDescriptionField
										name="Domain"
										databaseName="domain"
										description='Select the domain of the destination (the URL parameter that goes before ".cruncho.co") Be careful, only one of them may be correct. If you are not sure, ask a tech team member to check Cloudflare DNS config AND the wiki (there are known issues)'
										arrayOptions={domainOptions}
									/>
									<StringWithDescriptionField
										name="Custom Domain"
										databaseName="customDomain"
										description="Set the custom domain of the destination (optional, overwrites the domain parameter)"
									/>
									<StringSelectWithDescriptionField
										name="Language"
										databaseName="language"
										description="Select the language of the destination (optional, this will be appended after the domain)"
										arrayOptions={languageOptions}
									/>
									<StringWithDescriptionField
										name="Title"
										databaseName="title"
										description="Set the title of the iframe (optional)"
									/>
									<StringSelectWithDescriptionField
										name="Script"
										databaseName="script"
										description="Select the script to use"
										arrayOptions={scriptOptions}
									/>
									<BooleanWithDescriptionField
										name="Iframe Resizing"
										databaseName="iframeResizing"
										description="Enable iframe resizing. The client must have the corresponding parameter toggled in its admin settings."
									/>
								</Stack>
							</Grid>
							<Grid item>
								<Button type="submit" aria-label="generate">
									Generate
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
			<textarea
				className="w-full resize-none"
				value={code}
				readOnly
				style={{ height: "630px" }}
			/>
			<Typography>Preview</Typography>
			<div dangerouslySetInnerHTML={{ __html: code }}></div>
		</div>
	);
};
