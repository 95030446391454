import {
	CityFeatures,
	CityGeography,
	Destination,
	EventManagerFeatures,
	PhoneFormat,
	Point,
	PopulatedDestination,
} from "@cruncho/cruncho-shared-types";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

const defaultPhoneFormat: PhoneFormat = {
	country: "se",
	dialCode: false,
	disableCountryCode: false,
	disableDropdown: false,
	placeholder: "",
};

export const defaultEventManagerFeatures: EventManagerFeatures = {
	consentInformation: "",
	contactInformation: {
		bcc: undefined,
		cc: undefined,
		createdEmailAlert: undefined,
		mail: undefined,
		phone: undefined,
		phoneFormatted: undefined,
		name: "",
		language: "sv",
	},
	defaultLocation: {
		country: undefined,
		city: "",
		coordinates: {
			lat: 0,
			lng: 0,
		},
		address: "",
		venue: "",
	},
	deleteEventsNumberMonths: 24,
	printableEventList: false,
	quizEnabled: false,
	targetGroupField: false,
	postOptions: [],
	phoneFormat: defaultPhoneFormat,
	imageUploadLimitation: 10,
};

export const defaultCityFeatures: CityFeatures = {
	availableCCs: [],
	countryCode: "SE",
	currencyCode: "SEK",
	scoringScript: "cruncho_score",
	thresholdMinPhotos: 1,
	thresholdMinReviews: 0,
	thresholdMinScore: 2,
	timeZone: "Europe/Stockholm",
	eventManagerFeatures: defaultEventManagerFeatures,
	maximumMatchingDistance: 500,
	hideFilters: {
		areaFilter: false,
		dateFilter: false,
		venuesFilter: false,
		organizersFilter: false,
		onlineOnlyFilter: false,
		bookableFilter: false,
		openNowFilter: false,
		freeFilter: false,
	},
	hideBookableCarousel: {
		events: true,
		hotels: false,
		restaurants: false,
		visit: true,
	},
	hideHandpickedCarousel: false,
	hideNonBookableContent: {
		events: false,
		hotels: false,
		restaurants: false,
		visit: false,
	},
	showBookButton: {
		events: true,
		hotels: true,
		restaurants: true,
		visit: true,
	},
	showL1Category: {
		events: true,
		hotels: true,
		restaurants: true,
		visit: true,
	},
	showPrices: {
		events: true,
		hotels: true,
		restaurants: true,
		visit: true,
	},
};

export const defaultPoint: Point = {
	type: "Point",
	coordinates: { lat: 0, lng: 0 },
};

const defaultGeography: CityGeography = {
	geoCenterPt: cloneDeep(defaultPoint),
	geoNortheastPt: cloneDeep(defaultPoint),
	geoPolygon: {
		type: "Polygon",
		coordinates: [
			[
				{ lat: 0, lng: 0 },
				{ lat: 10, lng: 10 },
				{ lat: -10, lng: -10 },
			],
		],
	},
	geoSouthwestPt: cloneDeep(defaultPoint),
	googleCenterPt: cloneDeep(defaultPoint),
};

export const defaultCity: Destination = {
	areas: [],
	categories: {
		includeDefaults: true,
		l3: [],
	},
	bundle: "",
	clientName: "",
	clientType: "",
	features: cloneDeep(defaultCityFeatures),
	geography: cloneDeep(defaultGeography),
	labels: [],
	name: "",
	subdomainMatch: "",
	_id: "",
};

export const defaultPopulatedDestination: PopulatedDestination = {
	...defaultCity,
	organizers: [],
	venues: [],
	formats: [],
	categoryTree: [],
	isRaining: false,
};

/**
 * fill fields of of destination to have the same shape as the defaultCity
 * @param destination
 * @returns
 */
export function fillCityWithDefaultFields(
	destination: Destination,
): Destination {
	// cloning to avoid share same ref through defaultCity
	return merge(cloneDeep(defaultCity), destination);
}

/** *
 * @deprecated replace it with @link fillCityWithDefaultFields
 */
export function replaceMissingFeaturesWithDefault(
	rawDestinationFeatures: CityFeatures,
) {
	return {
		...rawDestinationFeatures,
		available_CCs: rawDestinationFeatures?.availableCCs ?? [],
		countryCode:
			rawDestinationFeatures?.countryCode ?? defaultCityFeatures.countryCode,
		currencyCode:
			rawDestinationFeatures?.currencyCode ?? defaultCityFeatures.currencyCode,
		scoringScript:
			rawDestinationFeatures?.scoringScript ?? defaultCityFeatures.scoringScript,
		thresholdMinPhotos:
			rawDestinationFeatures?.thresholdMinPhotos ??
			defaultCityFeatures.thresholdMinPhotos,
		thresholdMinReviews:
			rawDestinationFeatures?.thresholdMinReviews ??
			defaultCityFeatures.thresholdMinReviews,
		thresholdMinScore:
			rawDestinationFeatures?.thresholdMinScore ??
			defaultCityFeatures.thresholdMinScore,
		timeZone: rawDestinationFeatures?.timeZone ?? defaultCityFeatures.timeZone,
		mapControlsPosition: rawDestinationFeatures.mapControlsPosition ?? "",
	};
}

export interface CityFeaturesWithEventManagerFeatures
	extends Omit<CityFeatures, "eventManagerFeatures"> {
	eventManagerFeatures: EventManagerFeatures;
}
