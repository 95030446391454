import {
	BooleanWithDescriptionField,
	StringArrayWithDescriptionField,
	StringSelectWithDescriptionField,
	StringWithDescriptionField,
} from "@cruncho/components";
import { Destination } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useState } from "react";
import { api } from "services/api";
import { FormikDestinationWrapper } from "../FormikDestinationWrapper";

type GeneralTabProps = {
	/**
	 * The destination to edit
	 */
	destination: Destination;
	/**
	 * Triggered when clicking on save button
	 */
	onSave: (updatedDestination: Destination) => void;
};

/**
 * A tab displaying allowing edition of basic info at the root of a destination
 */
export function GeneralTab({ onSave, destination }: GeneralTabProps) {
	const [availableL3, setAvailableL3] = useState<string[]>([]);
	const [defaultL3, setDefaultL3] = useState<string[]>([]);

	// retrieving the list of all L3 for autcompletion purpose
	useEffect(() => {
		async function getAllL3() {
			const allL3 = (await api.category.l3.getAll()).map((l3) => l3.slug);
			setAvailableL3(allL3);
		}

		getAllL3();
	}, []);

	// retrieving the list of all default L3 categories
	useEffect(() => {
		async function getDefaultL3Categories() {
			const fetchedDefaultl3 = (await api.category.l3.getAll())
				.filter((l3) => l3.default)
				.map((l3) => l3.slug);
			setDefaultL3(fetchedDefaultl3);
		}
		getDefaultL3Categories();
	}, []);

	const timezones = useMemo(() => {
		const array = [];
		for (let i = -12; i <= 12; i += 1) {
			array.push(`UTC${i < 0 ? "" : "+"}${i}`);
		}
		return array;
	}, []);

	return (
		<FormikDestinationWrapper onSave={onSave} destination={destination}>
			<Grid container spacing={2}>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						databaseName="_id"
						description="The id of this new destination"
						name="Destination id (= slug)"
						disabled
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						databaseName="name"
						description="The name of this new destination"
						name="Destination name"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						databaseName="bundle"
						description="The bundle of this new destination"
						name="Destination bundle"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						databaseName="subdomainMatch"
						description="The subdomains of this new destination, separated with pipes |"
						name="Destination subdomains"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						databaseName="clientName"
						description="The name of the client"
						name="Destination Client name"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringSelectWithDescriptionField
						arrayOptions={["DMO", "Media"]}
						databaseName="clientType"
						description="The client's type (usually DMO or Media )"
						name="Destination Client type"
					/>
				</Grid>
				<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
					<Grid item xs={12}>
						<BooleanWithDescriptionField
							databaseName="categories.includeDefaults"
							description="Whether or not to include the default categories"
							name="Categories IncludeDefaults"
						/>
					</Grid>
					<Grid item xs={12}>
						<StringArrayWithDescriptionField
							arrayOptions={defaultL3}
							databaseName="categories.l3ToRemove"
							description="Array of default categories slugs"
							name="Default categories to hide on guide"
						/>
					</Grid>
					<Grid item xs={12}>
						<StringArrayWithDescriptionField
							arrayOptions={availableL3}
							databaseName="categories.l3ToHide"
							description="Array of category not showing on the guide"
							name="Categories to hide on guide"
						/>
					</Grid>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringArrayWithDescriptionField
						arrayOptions={availableL3}
						databaseName="categories.l3"
						description="Array of l3 slugs"
						name="Categories"
					/>
				</Grid>
				<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
					<Grid item xs={12}>
						<StringSelectWithDescriptionField
							arrayOptions={timezones}
							databaseName="timezone"
							description="Timezone of destination used for the Top Picks"
							name="Timezone"
						/>
					</Grid>
					<Grid item xs={12}>
						<StringWithDescriptionField
							databaseName="customTitle"
							description="Custom title of the browser tab"
							name="Custom title"
						/>
					</Grid>
					<Grid item xs={12}>
						<StringWithDescriptionField
							databaseName="favicon"
							description="Partial Digital Ocean link to the favicon, i.e. just `destination/image.png`"
							name="Favicon link"
						/>
					</Grid>
				</Grid>
			</Grid>
		</FormikDestinationWrapper>
	);
}
